export const GQL_ENDPOINT = window.location.hostname.includes("magicalkenyatravelspecialist.com") ? "https://magical-kenya-lms-api.devlab.zone/api/graphql" : window.location.hostname.includes("webflow.io") ? "https://magical-kenya-lms-dev-api.devlab.zone/api/graphql" : window.location.hostname.includes("devlab.zone") ? "https://magical-kenya-lms-staging-api.devlab.zone/api/graphql" : "http://localhost:4046/api/graphql"

export const S3_BASE_URL = window.location.hostname.includes("magicalkenyatravelspecialist.com") ? "https://kenya-lms-live.s3.amazonaws.com/" : window.location.hostname.includes("devlab.zone") ? "https://kenya-lms-staging.s3.amazonaws.com/" : "https://kenya-lms-dev.s3.amazonaws.com/";

export const ADMIN_PATHS = {
    signIn: "/admin/sign-in",
    adminVerification: "/admin/verification",
    forgotPassword: "/admin/forgot-password",
    resetPassword: "/admin/reset-password",
    dashboard: "/admin/overview",
    settings: "/admin/settings",
    userReports: "/admin/user/user-activity",
    userListing: "/admin/user/list",
    userDetails: "/admin/user/view",
    courseReports: "/admin/course/course-reports",
    courseListing: "/admin/course/list",
    courseDetail: "/admin/course/view",
    googleAnalytics: "/admin/analytics/ga",
};

export const PUBLIC_PATHS = {
    landingPage: "",
    signIn: "/user/sign-in",
    signUp: "/user/sign-up",
    countrySelection: "/user/select-country",
    userVerification: "/user/verification",
    dashboard: "/user/dashboard",
    forgotPassword: "/user/forgot-password",
    resetPassword: "/user/reset-password",
    settings: "/user/settings",
    courseList: "/user/courses",
    courseDetail: "/user/courses/(.*)",
    lessonDetail: "/lessons/(.*)",
    lessonDetailRoute: "/lessons",
    courseHighlightRoute: "/course-highlights",
    courseHighlight: "/course-highlights/(.*)",
    newsList: "/user/news",
    newsDetail: "/news/(.*)",
    newsDetailRoute: "/news",
    courseDetailRoute: "/user/courses",
    resources: "/user/resources",
    support: "/user/support",
    aiAssistant: "/user/ai-assistant"
};

const WORDS_PER_MINUTE = 200;