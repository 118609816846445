import { WFComponent, WFFormComponent } from "@xatom/core";
import { publicQL } from "../../graphql";
import { PublicUploadAvatarDocument, UserMeDocument } from "../../graphql/graphql";
import { S3_BASE_URL } from "../../config";
import { logoutPublicAuth } from "../../auth/public";
import { setWeglot } from "client-utils/utility-functions";
const defaultUsrAvatar = "https://assets-global.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg";
let loaderProgress = 0;

export const userSidebar = () => {
    console.log("Function executed");
    try {
        setWeglot();
        const userDataReq = publicQL.query(UserMeDocument);
        const avatarChangeReq = publicQL.mutation(PublicUploadAvatarDocument);
        const logoutBtn = new WFComponent(`[xa-type="sidebar-signout-btn"]`);
        logoutBtn.on("click", () => {
            logoutPublicAuth();
        });

        const sidebar = new WFComponent(`[xa-type="sidebar-component"]`);
        const avatar = sidebar.getChildAsComponent(`[xa-type="avatar"]`);
        const avatarGradient = avatar.getChildAsComponent(`[xa-type="border"]`);
        const userInfoBlock = sidebar.getChildAsComponent(`[xa-type="user-info"]`);
        const tierBadge = userInfoBlock.getChildAsComponent(`[xa-type="tier-badge"]`);

        userDataReq.onData((data) => {
            if (!data.userMe.enabled) {
                logoutPublicAuth();
            } else {
                avatar.updateTextViaAttrVar({
                    initials: getInitials(`${data.userMe.firstName} ${data.userMe.lastName}`)
                });
                const userTier = data.userMe.tier.name
                switch (userTier) {
                    case 'Bronze':
                        avatarGradient.addCssClass("is-bronze");
                        tierBadge.addCssClass("is-bronze");
                        break;
                        
                        case 'Silver':
                            avatarGradient.addCssClass("is-silver");
                            tierBadge.addCssClass("is-silver")
                            break;
                            
                            case 'Gold':
                                avatarGradient.addCssClass("is-gold");
                                tierBadge.addCssClass("is-gold");
                                break;
                                
                                default:
                                    break;
                                }
                                
                                userInfoBlock.updateTextViaAttrVar({
                                    "user-name": `${data.userMe.firstName} ${data.userMe.lastName}`,
                                    "user-email": data.userMe.email
                                });
                                
                                
                                tierBadge.updateTextViaAttrVar({
                                    tier: data.userMe.tier?.name
                                });

                userInfoBlock.removeCssClass("hide");
            }
        });
        
        userDataReq.fetch();

        // //Update user Avatar
        // userAvatarInput.on("change", () => {
        //     if (userAvatarForm.getFormData() && userAvatarForm.getFormData().img) {
        //         const image = userAvatarForm.getFormData().img;
        //         userImage.addCssClass("hide");
        //         avatarLoaderWrap.removeCssClass("hide");
        //         avatarChangeReq.fetch({
        //             imageFile: image
        //         });
        //     }
        // });
        // userAvatarForm.onFormSubmit((data) => {
        //     //do nothing
        // });

        // avatarChangeReq.onError((err) => {
        // })

        // avatarChangeReq.onData((data) => {
        //     userAvatarForm.resetForm();
        //     userImage.setAttribute("src", `${data.publicUploadAvatar}`);
        //     userImage.setAttribute("srcset", `${data.publicUploadAvatar}`);
        //     userImage.removeCssClass("hide");
        //     avatarLoaderWrap.addCssClass("hide");
        // })
    } catch (err) {
        console.log("We think this page doesn't have a sidebar. Check this error - ", err.message);
    }


    function getInitials(name) {
        const words = name.split(' ');
        const initials = words.map(word => word.charAt(0).toUpperCase()).join('');
        return initials;
    }


    function getRandomValueFromArray(array) {
        const randomIndex = Math.floor(Math.random() * array.length);
        return array[randomIndex];
    }

    function loaderAnimation(loader) {
        loader.getElement().style.background = `conic-gradient(#00B8B4 ${loaderProgress * 3.6}deg, #ededed 0deg)`;
        loaderProgress = (loaderProgress + 1) % 100;
    }
}
